import { ReactNode, createContext } from "react";
import log from "loglevel";
import useAdminAPIService from "@hooks/useAdminAPIService";
import { useState } from "react";
import { useQuery } from '@tanstack/react-query'
import { Stallholder } from "@sharedtypes/Stallholder";

const StallholdersContext = createContext<StallholdersContextValue | undefined>(undefined);

type StallholdersContextProps = {
  children?: ReactNode
};

type StallholdersContextValue = {
  stallholders: Stallholder[];
  findStallholderByDealerID: (id: string) => Stallholder | undefined;
  findStallholderByID: (id: string) => Stallholder | undefined;
};

export const StallholdersProvider = ({ children }: StallholdersContextProps) => {

  const [stallholders, setStallholders] = useState<Stallholder[]>([]);
  const apiService  = useAdminAPIService();
  
  useQuery({
    queryKey: ['stallholders'], 
    queryFn:() =>
      apiService.fetch("getStallholders", {})
      .then((data) => {
        log.debug("Stallholders fetched " + JSON.stringify(data));

        const filteredArray = data.filter((stallholder: Stallholder) => !stallholder.hide);

        const sortedArray = filteredArray.sort((obj1: Object, obj2: Object) => {
          const key1 = "dealer_id" as keyof typeof obj1;
          const key2 = "dealer_id" as keyof typeof obj2;
    
          const n1 = parseInt(obj1[key1].toString());
          const n2 = parseInt(obj2[key2].toString());

          if (n1 > n2) {
              return 1;
          }
      
          if (n1 < n2) {
              return -1;
          }
      
          return 0;
        });        
        setStallholders(sortedArray);
        log.info("sorted " + JSON.stringify(sortedArray));
        return sortedArray;
      })
      .catch(function (error) {
        log.info("fetch error:" + error);
    }),
    staleTime: Infinity,
    refetchInterval: 6000000,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  })

  function findStallholderByDealerID (id: string) {
    
    return stallholders.find((stallholder) => { 

      //log.debug("findStallholderByDealerID", stallholder);
      return stallholder.dealer_id === id
    })
  }

  function findStallholderByID (id: string) {
    
    return stallholders.find((stallholder) => { 

      //log.debug("findStallholderByID", stallholder);
      return stallholder.id === id
    })
  }

  const value: StallholdersContextValue = {
    stallholders,
    findStallholderByDealerID,
    findStallholderByID
  }

  return (
    <StallholdersContext.Provider value={value}>
      {children}
    </StallholdersContext.Provider>
  );
};


export default StallholdersContext;
